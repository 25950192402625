// wrapPageElement and wrapRootElement should be the same between gatsby-ssr.tsx and gatsby-browser.tsx
// @see https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-script/#usage-in-gatsby-ssr-and-browser-apis
import { Script } from 'gatsby';

import type { GatsbySSR } from 'gatsby';

// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element }) => (
  <>
    <Script type="text/javascript" src="https://cdn.bizible.com/scripts/bizible.js" strategy="off-main-thread" />
    {element}
  </>
);
